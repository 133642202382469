h1 {
  color: darkmagenta;
}
h9 {
  color: darkOrLight42;

  background-color: darkOrLight10;
}

::placeholder {
  font-style: italic;
}

.public-DraftEditorPlaceholder-inner {
  font-style: italic;
}

#comment-field-label {
  font-style: italic;
}

/*Hide vertical arrow buttons in number input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
